import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faLocationDot, faMicrochip } from '@fortawesome/free-solid-svg-icons'

import KoneLogo from '/src/images/samarbete/kone.svg'

const SamarbeteKonePage = () => {
	return (
		<Layout pageTitle="Kone" showBox={false}>

			<div className="pageRows">

				{/*
				<div style={{textAlign: 'center'}}>
					<div style={{display: 'inline-block'}}>
						<div>
							<h1 className="center" style={{fontSize: '58px'}}>Kone</h1>
							<p style={{fontSize: '22px', marginTop: '25px'}}>XXX</p>
						</div>
					</div>
				</div>
				*/}

				<div>
					<div>
						<div className="rowIcon">
							<img src={KoneLogo} alt="" />
						</div>
						<div className="rowText">
							<h2>Smarta hissar från Kone</h2>
							<p>Med hjälp av smarta uppkopplade hissar från Kone kan boende enkelt i Brfify-appen se hissarnas status och vid problem övergripande information om ärende, exempelvis om tekniker från Kone är på väg, på plats eller om ärendet är avslutat. Boende kan även få notis vid förändrad hiss-status så slipper de manuellt att titta i appen.</p>

							<p>Dessutom kan boende åka hiss via Brfify-appen! Med en enda knapptryckning kommer hissen från och till önskat våningsplan, exempelvis direkt från entréplan till lägenhetens våningsplan. Med appen slipper boende även att ange hisskod om sådan finns, exempelvis till källaren.</p>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faLocationDot} />
						</div>
						<div className="rowText">
							<h2>Möt Brf Gripen 1</h2>
							<p>Krånglande nödtelefoner efter byggfel var starten på att bli först i världen för Bostadsrättsföreningen Gripen 1 i Örebro. I videon berättar föreningen om sin resa och vilka funktioner som numera erbjuds till de boende.</p>

							<iframe width="200" height="450" src="https://www.youtube.com/embed/SJevMRncY6k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{ width: '100%' }} allowfullscreen></iframe>

							<p>
								<br/>
								<a href="https://www.kone.se/berattelser-och-referenser/referenser/brf-gripen-utvecklade-en-egen-app-for-att-aka-hiss.aspx" className="inlineButton" target="_blank">Läs mer på kone.se</a>
							</p>
						</div>
					</div>
				</div>

				<div>
					<div>
						<div className="rowIcon">
							<FontAwesomeIcon icon={faMicrochip} />
						</div>
						<div className="rowText">
							<h2>Hur fungerar det?</h2>
							<p>Genom att koppla upp Brfify till KONEs API:er, koppla samman systemen, möjliggörs funktionerna beskrivna på denna sida, enkelt och säkert för de boende. Kontakta ditt lokala KONE-team för att samtala kring möjligheterna med era hissar.</p>

							<p style={{ marginBottom: '0px' }}>
								KONEs API:er som används:
							</p>
							<ul>
								<li>Elevator Call API</li>
								<li>Equipment Status API</li>
							</ul>

							<p>
								<a href="https://www.kone.se/befintliga-byggnader/uppkopplade-hissar/" className="inlineButton" target="_blank">Läs mer om uppkopplade hissar</a>
							</p>
						</div>
					</div>
				</div>

			</div>

		</Layout>
	)
}

export default SamarbeteKonePage